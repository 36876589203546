import React, { useState, useRef, useEffect } from 'react';

import {Link} from "gatsby";

function FeaturesHome() {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    // heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <div>
      
    <section className="relative bg-white">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h1 mb-4 text-4xl font-extrabold leading-tight tracking-tighter" id="features">Rapidly create and export backgrounds</h1>
            <p className="text-xl text-gray-600">In a matter of clicks, you can find a template you like, customize it, 
            and then export it for use on your website. Over 50 backgrounds available, and growing!</p>
          </div>

        
          {/* Section tags */}
          {/* <div className="border-b border-gray-300 pb-4 mb-12">
            <ul className="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
              <li className="mx-5 my-1">
                <a className="text-blue-600" href="#0">All</a>
              </li>
              <li className="mx-5 my-1">
                <a className="text-gray-800 hover:underline" href="#0">Tutorials</a>
              </li>
              <li className="mx-5 my-1">
                <a className="text-gray-800 hover:underline" href="#0">Tips & Tricks</a>
              </li>
              <li className="mx-5 my-1">
                <a className="text-gray-800 hover:underline" href="#0">Templates</a>
              </li>
              <li className="mx-5 my-1">
                <a className="text-gray-800 hover:underline" href="#0">Guides</a>
              </li>
            </ul>
          </div> */}

          {/* Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none">

            {/* Articles container */}
            <div className="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">

              {/* 1st article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out">
                <header>
                  {/* <Link to="/app?designType=svgOverlay" className="block mb-6"> */}
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../public/img/svg_waves.jpg')} width="352" height="198" alt="An SVG Overlay background, containing a wave SVG on an
                      animated particle background" /> 
                    </figure>
                  {/* </Link> */}
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                  SVG Wave Background
                    {/* <Link to="/app?designType=svgOverlay" className="hover:underline"></Link> */}
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Waves on a background of your choice, great for landings!</p>

              </article>

              {/* 2nd article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
                <header>
                  {/* <Link to="/app?designType=triangle" className="block mb-6"> */}
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../public/img/triangle_background.jpg')} width="352" height="198" alt="Triangle pattern background" />
                    </figure> 
                  {/* </Link>  */}
                  <div className="mb-3">
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                  Triangle Background
                    {/* <Link to="/app/?designType=triangle" className="hover:underline"></Link> */}
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">A popular option, consisting of a triangle background, displayed as an SVG graphic.</p>
              </article>

              {/* 3rd article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="300">
                <header>
                  {/* <Link to="/app?template=PMh5d0FLzboDcJWwl1CH" className="block mb-6"> */}
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../public/img/particle_background.jpg')} width="352" height="198" alt="Particles on a gradient background" 
                      />
                    </figure>
                  {/* </Link>  */}
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                  Animated Particles
                    {/* <Link to="/app?template=PMh5d0FLzboDcJWwl1CH" className="hover:underline"></Link> */}
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Animated particles on gradient backgrounds, with over 50 templates and counting!</p>

              </article>

              {/* 4th article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out">
                <header>
                  {/* <Link to="/app?designType=gradient" className="block mb-6"> */}
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <div className="absolute inset-0 w-full h-full object-cover transform scale-105 preview_gradient hover:-translate-y-1 transition duration-700 ease-out"
                      width="352" height="198" />
                    </figure>
                  {/* </Link> */}
                  <div className="mb-3">
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    Gradient
                    {/* <Link to="/app?designType=gradient" className="hover:underline"></Link> */}
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">A CSS gradient, with over 60 to choose from!</p>

              </article>

              {/* 5th article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
                <header>
                  {/* <Link to="/app?template=9xSjGQZzF7DMXcVm5FOb" className="block mb-6"> */}
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../public/img/constellations.jpg')} width="352" height="198" alt="A group of constellations on a dark background" />
                    </figure> 
                  {/* </Link> */}
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                  Constellation Backgrounds
                    {/* <Link to="/app?template=9xSjGQZzF7DMXcVm5FOb" className="hover:underline"></Link> */}
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Animated constellations on a gradient background</p>
              </article>

              {/* 6th article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="300">
                <header>
                  {/* <Link to="/app?designType=animatedGradient" className="block mb-6"> */}
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <div className="absolute inset-0 w-full h-full object-cover preview_gradient gradient_two transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" width="352" height="198"/>
                    </figure>
                  {/* </Link> */}
                  <div className="mb-3">
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                  Animated Gradient
                    {/* <Link to="/app?designType=animatedGradient" className="hover:underline"></Link> */}
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">A slight variant on the Gradient, adding animated functionality via CSS.</p>

              </article>

            </div>

          </div>


        </div >

        
      </div >
      </section >
    </div>
  );
}

export default FeaturesHome;
